import "bootstrap/dist/css/bootstrap.min.css"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { api } from "../../../api"
import { IExhibition } from "../../../types"
import { wrapInQuotes } from "../../../helpers/stringFormatters"

const PortfolioCreateExhibitionPage = () => {
  const navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.target as HTMLFormElement
    const formData = new FormData(form)

    const data = {
      name: formData.get("name"),
      description: formData.get("description"),
      location: formData.get("location"),
      year: formData.get("year"),
      content_authors: formData.get("contentAuthors"),
    }

    const mainPhoto = form.querySelector<HTMLInputElement>("#mainPhoto")!.files![0]
    const galleryPhotos = form.querySelector<HTMLInputElement>("#galleryPhotos")!.files!
    
    // TODO: Рефактор
    // Фото из формы
    const photosForm = new FormData()
    photosForm.append("main_photo", mainPhoto)
    
    for (const galleryPhoto of galleryPhotos) {
      photosForm.append("photos", galleryPhoto)
    }

    // Создание выставки первым запросом
    try {
      const response = await api.post("portfolio/exhibitions", data)
      toast.success(response?.data?.message || "Выставка создана")

      // Поиск id только-что созданной выставки
      const allExhibitions = (await api.get<IExhibition[]>("portfolio/exhibitions")).data
      const createdExhibitionId = allExhibitions.find(e => e.name === data.name)?.id

      // На всякий
      if (!createdExhibitionId) {
        console.error("Попытка запросить все выставки и найти выставку с называнием как в форме - провалилась")
        toast.error("Не удалось добавить фотографии в выставку")
        return
      }

      // Добавление фото в только-что созданную выставку вторым запросом
      try {
        await api.post(`portfolio/exhibitions/${createdExhibitionId}/upload_photos`, photosForm)
        toast.success("Фотографии успешно добавлены в выставку")
        navigate("/portfolio/exhibitions")
      } catch(err) {
        console.error(err)
        toast.error("Не удалось добавить фотографии в выставку")
      }

    } catch(err) {
      console.error(err)
      toast.error("Ошбика, не удалось добавить выставку")
    } 
  }

	return (
		<div>
      <header className="ns_categories__header">
        <img src="/img/logo.svg" alt="Image" className="ns_categories__logo"/>
        <button type="button" className="btn btn-primary" onClick={() => navigate("/portfolio/exhibitions")}>
          Назад
        </button>
      </header>
      <main className="container pt-5">
        <form className="card" onSubmit={handleSubmit}>
          <div className="card-header">Добавить выставку</div>
          <div className="card-body">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Название</label>
              <button className="btn btn-outline-dark ms-2 py-0" type="button" onClick={(e) => wrapInQuotes("name")}>« »</button>
              <input required type="text" className="form-control" name="name" id="name"/>
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">Описание</label>
              <input required type="text" className="form-control" name="description" id="description"/>
            </div>
            <div className="mb-3">
              <label htmlFor="location" className="form-label">Локация</label>
              <input required type="text" className="form-control" name="location" id="location"/>
            </div>
            <div className="mb-3">
              <label htmlFor="year" className="form-label">Дата</label>
              <input required type="date" className="form-control" placeholder="ГГГГ-ММ-ДД" name="year" id="year"/>
            </div>
            <div className="mb-3">
              <label htmlFor="contentAuthors" className="form-label">Авторы (не обязательно)</label>
              <input type="text" className="form-control" name="contentAuthors" id="contentAuthors"/>
            </div>

            <div className="mb-3">
              <label htmlFor="mainPhoto" className="form-label">Главная фотография</label>
              <input className="form-control" type="file" id="mainPhoto" required/>
            </div>
            <div className="mb-3">
              <label htmlFor="galleryPhotos" className="form-label">Галерея (для симметричности предпочтительно чётное количество)</label>
              <input className="form-control" type="file" multiple id="galleryPhotos" required/>
            </div>

    
            <button type="submit" className="btn btn-primary">Создать</button>
          </div>
          
        </form>
      </main>
    </div>
	)
}

export default PortfolioCreateExhibitionPage
