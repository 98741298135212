import { AxiosResponse } from "axios"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"
import { api } from "../../../api"
import { IStoreCategory, IStoreProduct } from "../../../types"
import "./NetStoreProduct.css"
import UpdateImagesModal from "./UpdateImagesModal/UpdateImagesModal"

const NetStoreProductPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [product, setProduct] = useState<null | IStoreProduct>(null)
  const [categories, setCategories] = useState<null | IStoreCategory[]>(null) // Temp

  const fetchProduct = () => {
    api
      .get(`internet_store/products/${id}`)
      .then((res: AxiosResponse<IStoreProduct>) => {
        setProduct(res.data)
      })
      .catch((err) => {
        toast.error("Не получилось запросить продукт")
        console.error(err)
      })
  }

  // Temp
  const fetchCategories = () => {
    api
      .get("internet_store/categories")
      .then((response: AxiosResponse<IStoreCategory[]>) => {
        setCategories(response.data)
      })
      .catch((err) => {
        toast.error("Не удалось загрузить список категорий. Соответствующая колонка в таблице будет пустой.")
        console.error(err)
        
      })
  }

  const handleDeleteImage = (image_id: number) => {
    api
      .delete(`internet_store/products/${product!.id}/delete_image`, {
        params: { image_id }
      })
      .then(() => {
        toast.success("Изображение удалено", {
          onDismiss: () => {navigate("/store/products/popular")}
        })
        fetchProduct()
      })
      .catch((err) => {
        toast.error("Не удалось удалить изображение")
        console.error(err)
      })
  }

  const handleDeleteProduct = (product_id: number) => {
    if (!window.confirm(`Удалить продукт`)) return

    api
      .delete(`/internet_store/products/${product_id}`)
      .then(() => {
        toast.success("Продукт успешно удалён")
        navigate("/store/products")
      })
      .catch((err) => {
        toast.error("Не удалось удалить выставку")
        console.error(err)
      })
  }

  const handleMakePopular = (product_id: number) => {
    api
      .post("internet_store/products/pop_products", null, {
        params: { product_id}
      })
      .then(() => {
        toast.success("Продукт успешно помечен как популярный", {
          description: "Потяните это уведомление вверх что бы посмотреть",
          onDismiss: () => {navigate("/store/products/popular")}
        })
      })
      .catch((err) => {
        toast.error("Не удалось пометить продукт как популярный")
        console.error(err)
      })
  }

  useEffect(() => {
    fetchProduct()
    fetchCategories()
  }, [])

  if (!product) return <h1 className="text-white text-center mt-5">Загрузка...</h1>

  return (
    <div>
      <main className="product_info__main">
        <div className="card">
          <div className="card-header product_info__main-card-header-title">Продукт: {product.title}</div>
          <div className="card-body">
            <h5 className="card-title">Цена</h5>
            <h6 className="card-subtitle mb-2 text-muted">{product.price} руб.</h6>
            <hr />
            <h5 className="card-title">Описание</h5>
            <h6 className="card-subtitle mb-2 text-muted white-space-normal desc">{product.description}</h6>
            <hr />
            <h5 className="card-title">Размер</h5>
            <h6 className="card-subtitle mb-2 text-muted">{product.size}</h6>
            <hr />
            <h5 className="card-title">Наличие</h5>
            <h6 className="card-subtitle mb-2 text-muted">
              {
                product.is_available ? "Да" : "Нет"
              }
            </h6>
            <hr />
            <h5 className="card-title">Артикул</h5>
            <h6 className="card-subtitle mb-2 text-muted">{product.vendor_code}</h6>
            <hr />
            <h5 className="card-title">Вес</h5>
            <h6 className="card-subtitle mb-2 text-muted">{product.weight}</h6>
            <hr />
            <h5 className="card-title">Срок производства</h5>
            <h6 className="card-subtitle mb-2 text-muted">{product.term_production} дн.</h6>
            <hr />
            <h5 className="card-title">Материал</h5>
            <h6 className="card-subtitle mb-2 text-muted">{product.material}</h6>
            <hr />
            <h5 className="card-title">Категория</h5>
            {/* <h6 className="card-subtitle mb-2 text-muted">{ product.category }</h6> */}
            <h6 className="card-subtitle mb-2 text-muted">{categories?.find(c => c.id === product.category_id)?.title || "???"}</h6>
            <hr />
            <h5 className="card-title">Цвет</h5>
            <h6 className="card-subtitle mb-2 text-muted">{product.colors?.join(", ")}</h6>
            <hr />
            <h5 className="card-title">Чертеж</h5>
            <h6 className="card-subtitle mb-2 text-muted">
              {
                product.design
                  ? <a href={product.design.link_path} className="link-primary" download="">Ссылка на чертёж</a>
                  : "Чертёж отсутсвует"
              }
            </h6>
            <hr />
            <h5 className="card-title">Изображения</h5>
            <h6 className="mb-2 text-muted product-info__images">
              {
                product.images.map(image => (
                  <div key={`image_${image.id}`}>
                    <img src={image?.link_path || "/img/placeholder.jpg"} />
                    <button onClick={() => handleDeleteImage(image.id)} className="btn btn-light btn-sm">Удалить</button>
                  </div>
                ))
              }
              {
                product.images.length === 0 && (
                  "Изображения отсутсвуют"
                )
              }
            </h6>
            <hr />
            <div className="product-info__buttons">

              <button
                onClick={() => handleMakePopular(product.id)}
                className="btn btn-primary"
              >Cделать популярным</button>

              <button
                onClick={() => handleDeleteProduct(product.id)}
                className="btn btn-danger"
              >Удалить</button>

              <button
                onClick={() => navigate(`/store/products/update/${id}`)}
                className="btn btn-primary"
              >Обновить инфо.</button>

              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#updateImagesModal">
                Добавить изображения
              </button>
            </div>
          </div>
        </div>

        <UpdateImagesModal product={product} onSuccess={fetchProduct}/>
      </main>
    </div>
  )
}

export default NetStoreProductPage
