import { Link } from "react-router-dom"
import "./StaffList.css"
import "bootstrap/dist/css/bootstrap.min.css"

const StaffListPage = () => {
  return (
    <div className="adminka__body">
      <div className="adminka__main-message" id="hider-message">
      {/*
        Some Message
        <button id="hider"><img src="/img/exit.png" alt="Image" /></button>
      */}
      </div>
      <header className="adminka__header">
        <div className="adminka__header-flex-element">
          <img src="/img/logo.svg" alt="Image" className="adminka__logo" />
        </div>
        <Link to="store-home">
          <button type="button" className="btn btn-primary">Управление интернет-магазином</button>
        </Link>
        <Link to="/workers-add-worker">
          <button type="button" className="btn btn-primary">Добавить работника</button>
        </Link>
        <button type="button" className="btn btn-primary">Выйти</button>
      </header>
      <main className="adminka__main">
        <nav className="navbar bg-dark adminka__search">
          <div className="container-fluid">
            <form className="d-flex" role="search" method="get">
              <input className="form-control me-2" type="search" placeholder="Поиск" name="search"
                value="" />
            </form>
          </div>
        </nav>
        <table className="table caption-top adminka__table">
          <caption>Работники</caption>
          <thead>
            <tr>
              <th scope="col">Ф.И.О.</th>
              <th scope="col">Должность</th>
              <th scope="col">Номер телефона</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            <tr>
              <td>sdklfklsfdkls</td>
              <td>sdlkjldjksflds</td>
              <td>92834798247892</td>
              <td>
                <button type="button" className="btn btn-dark">Подробнее</button>
              </td>
              <td>
                <form action="{{ url_for('staff.remove_worker', user_id=user.id) }}" method="post">
                  <button type="submit" className="btn btn-danger">Удалить</button>
                </form>
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    </div>
  )
}

export default StaffListPage
