import { toast } from "sonner"
import { api } from "../../../../api"
import { IStoreProduct } from "../../../../types"
import "bootstrap/dist/css/bootstrap.min.css"

interface IProps {
  product: IStoreProduct,
  onSuccess?: () => void
}

const UpdateImagesModal = ({ product, onSuccess }: IProps) => {

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    
    const designFile = formData.get("design") as File | null
    const imageFiles = formData.getAll("images") as File[] | null


    if (designFile?.size !== 0) { // If file uploaded
      await api.postForm(`internet_store/products/${product.id}/upload_design`, {
        file: designFile
      })
      toast.success("Чертёж успешно обновлён")
    }

    if (imageFiles?.[0]?.size !== 0) { // If file uploaded
      await api.postForm(`internet_store/products/${product.id}/upload_images`, {
        files: imageFiles
      })
      toast.success("Фотографии успешно добавлены")
    }

    onSuccess?.()
    const closeButton = form.querySelector("button[data-bs-dismiss='modal']") as HTMLButtonElement | undefined
    closeButton?.click?.()
  }

	return (
    <div className="modal fade" id="updateImagesModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <form className="modal-content" onSubmit={handleSubmit}>
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Добавить изображения</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div >
              <label htmlFor="design" className="form-label">Чертеж</label>
              <input type="file" className="form-control" id="design" name="design"/>
              <div className="form-text">К сожалению, функция изменения загруженного рание чертежа пока находится в разработке
                
              </div>
              <br/>
              <label htmlFor="images" className="form-label">Изображения</label>
              <input type="file" multiple className="form-control" id="images" name="images" />
            </div>
            <div className="modal-footer pb-0 border-top-0">
              <button className="btn btn-primary" type="submit">Добавить</button>
              <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Отмена</button>
            </div>
          </div>
        </form>
      </div>
    </div>
	)
}

export default UpdateImagesModal
