import { useNavigate } from "react-router-dom"
import "./ForDevUsers.css"
import "bootstrap/dist/css/bootstrap.min.css"

const ForDevUsersPage = () => {
  const navigate = useNavigate()
	return (
		<div className="d-flex flex-column min-vh-100">
      <header className="ns_categories__header">
        <img src="/img/logo.svg" alt="Image" className="ns_categories__logo"/>
        <button type="button" className="btn btn-primary" onClick={() => navigate("/dev/home")}>
          Назад
        </button>
      </header>

      <iframe src="https://sso.decoratoria.ru/admin/sso_decoratoria/console/#/sso_decoratoria/users" className="flex-grow-1"/>
		</div>
	)
}

export default ForDevUsersPage
