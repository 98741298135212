import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "./NetStoreOrder.css"
import { useNavigate, useParams } from "react-router-dom"
import { IStoreOrder } from "../../../types"
import L, { Icon } from "leaflet"
import { LatLngTuple } from "leaflet"
import { useEffect, useState } from "react"
import "../../../../public/styles/leaflet.css"
import { toast } from "sonner"
import { api } from "../../../api"
import { AxiosResponse } from "axios"
import { formatDate } from "../../../helpers/stringFormatters"

const NetStoreOrderPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [order, setOrder] = useState<null | IStoreOrder>(null)

  useEffect(() => {
    api
      .get(`internet_store/orders/${id}`)
      .then((res: AxiosResponse<IStoreOrder>) => {
        setOrder(res.data)
      })
      .catch((err) => {
        console.error(err)
        toast.error("Не получилось загрузить данные о заказе")
      })
  }, [])
  
  useEffect(() => {
    try {
      if (!order) return

      if (!order.additional_info.address.geo) {
        setTimeout(() => {
          toast.error("Нету данных о геопозиции, карта отключена")
        }, 0)
        throw new Error("No geoposition data")
      }

      const position: LatLngTuple = [order.additional_info.address.geo?.lng, order.additional_info.address.geo?.lat]
      const map = L.map('map', { scrollWheelZoom: false }).setView(position, 11)
      const icon = new Icon({
        iconUrl: "/img/marker.svg",
        iconSize: [60, 60],
      })

      L.marker(position, { icon }).addTo(map);

      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(map);
    }
    catch (err: any) {
      console.error("Can't initialize map.\n", err.message)
    }
  }, [order])


  if (!order) return <h1 className="text-white text-center mt-5">Загрузка...</h1> // <Loader/>

  const handleStatusChange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const newStatus = formData.get("newStatus")
    const newEmail = formData.get("newEmail")

    if (newStatus === null || newStatus === "invalid") {
      toast.error("Укажите новый статус заказа")
      return
    }

    api
      .patch(`internet_store/orders/${order.id}`, {
        status: newStatus,
        customer_email: newEmail
      })
      .then((res: AxiosResponse<any>) => {
        console.log("res: ", res)
        toast.success(`Успешною. Новый статус заказа: ${newStatus}`)
      })
  }

  return (
    <div>
      <header className="ns_orders__header">
        <img src="/img/logo.svg" alt="Image" className="ns_orders__logo"/>
        <button type="button" className="btn btn-primary"
          onClick={() => navigate("/store/orders")}>Назад</button>
      </header>
      <main className="ns_order__main">
        <div className="ns_order__main-title">
          Заказ номер: {id}
        </div>
        <div className="row ns_order__main-row">
          <div className="col ns_order__main-subtitle">
            <strong>Данные покупателя</strong> <br /> <br />
            <span className="fw-bold">ФИО:</span> {order.additional_info.first_name} {order.additional_info.last_name} {order.additional_info.patronymic}

            <hr />
            <span className="fw-bold">Номерa телефонов:</span>
            <span className="bg-secondary-subtle px-2 rounded-2 m-1 ns_order__phone">{order.additional_info.phone_number}</span>
            {
              !!order.additional_info.extra_phone_number && (
                <span className="bg-secondary-subtle px-2 rounded-2 m-1 ns_order__phone">{order.additional_info.extra_phone_number}</span>
              )
            }

            <hr />
            <span className="fw-bold">Email:</span> {order.additional_info.email}

            <hr />
            <span className="fw-bold">Адрес:</span> {order.additional_info.address.address_string}, кв. {order.additional_info.address.flat}<br />
            <span className="fw-bold">Подъезд:</span> {order.additional_info.address.entrance}<br />
            <span className="fw-bold">Домофон:</span> {order.additional_info.address.intercom}<br />
            <span className="fw-bold">Этаж:</span> {order.additional_info.address.floor}<br />
          </div>

          <div className="col ns_order__main-subtitle">
            <div className="row">
              <div className="col">
                <strong>Данные заказа</strong><br/><br/>
              </div>
            </div>
            <span className="fw-bold">Статус: </span>{order.status}<br />
            <span className="fw-bold">Дата: </span><span id="time">{formatDate(order.time)}</span> <br />
            <span className="fw-bold">Итоговая цена: </span>{order.total_price} руб.

            <button type="button" className="btn btn-primary d-block mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Обновить заказ
            </button>
          </div>
        </div>
        <div className="ns_order__main-title mt-5">
          Наименования
        </div>

        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <form className="modal-content" onSubmit={handleStatusChange}>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Изменить статус заказа</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <strong className="me-2">Текущий статус:</strong>{order.status}
                <br />
                <br />
                <strong>Почта покупателя:</strong>
                <input
                  type="text"
                  defaultValue={order.additional_info.email}
                  className="form-control"
                  name="newEmail"
                  id="newEmail"
                  placeholder="Почта"
                  aria-label="email"
                />
                <br />
                <strong>Новый статус:</strong>
                <select
                  className="form-select mt-1"
                  aria-label="Новый статус:"
                  name="newStatus"
                  id="newStatus"
                  defaultValue="invalid"
                >
                  <option value="invalid" disabled hidden>Выбирите статус...</option>
                  <option value="в ожидании">В ожидании</option>
                  <option value="в процессе">В процессе</option>
                  <option value="подтверждён">Подтверждён</option>
                  <option value="отменён">Отменён</option>
                  <option value="выполнен">Выполнен</option>
                </select>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Отмена</button>
                <button className="btn btn-primary" type="submit">Сохранить</button>
              </div>
            </form>
          </div>
        </div>

        <div className="ns_order__main-items">
          {
            order.items.map((item) => (
              <div className="row" key={`order-item-${item.id}`}>
                <div className="col-5">
                  <img src={item.product.images[0].link_path} className="ns_order__main-items-img" />
                </div>
                <div className="col-3">{item.product.title}</div>
                <div className="col-2">Цвет: {item.color}</div>
                <div className="col-2">{item.amount} шт.</div>
              </div>
            ))
          }
        </div>
        <div id="map" className="map"></div>
      </main>
    </div>
  )
}

export default NetStoreOrderPage
