import { BrowserRouter, Route, Routes } from "react-router-dom"
import AddWorkerPage from "./components/pages/AddWorker/AddWorker"
import HomePage from "./components/pages/Home/Home"
import NetStoreCategoriesPage from "./components/pages/NetStoreCategories/NetStoreCategories"
import NetStoreCreateCategoryPage from "./components/pages/NetStoreCreateCategory/NetStoreCreateCategory"
import NetStoreCreateProductPage from "./components/pages/NetStoreCreateProduct/NetStoreCreateProduct"
import NetStoreHomePage from "./components/pages/NetStoreHome/NetStoreHome"
import NetStoreOrdersPage from "./components/pages/NetStoreOrders/NetStoreOrders"
import NetStoreProductsPage from "./components/pages/NetStoreProducts/NetStoreProducts"
import PortfolioHomePage from "./components/pages/PortfolioHome/PortfolioHome"
import StaffListPage from "./components/pages/StaffList/StaffList"
import WelcomePage from "./components/pages/Welcome/Welcome"
import PortfolioCreateProjectPage from "./components/pages/PortfolioCreateProject/PortfolioCreateProject"
import PortfolioCreateExhibitionPage from "./components/pages/PortfolioCreateExhibition/PortfolioCreateExhibition"
import PortfolioExhibitionsPage from "./components/pages/PortfolioExhibitions/PortfolioExhibitions"
import PortfolioProjectsPage from "./components/pages/PortfolioProjects/PortfolioProjects"
import PortfolioEditProjectPage from "./components/pages/PortfolioEditProject/PortfolioEditProject"
import PortfolioEditExhibitionPage from "./components/pages/PortfolioEditExhibition/PortfolioEditExhibition"
import CallbackPage from "./components/pages/CallbackPage/CallbackPage"
import ForDevHomePage from "./components/pages/ForDevHome/ForDevHome"
import ForDevUsersPage from "./components/pages/ForDevUsers/ForDevUsers"
import LogoutPage from "./components/pages/Logout/Logout"
import NetStoreUpdateCategoryPage from "./components/pages/NetStoreUpdateCategory/NetStoreUpdateCategory"
import NetStoreOrderPage from "./components/pages/NetStoreOrder/NetStoreOrder"
import NetStorePopProductsPage from "./components/pages/NetStorePopProducts/NetStorePopProducts"
import NetStoreProductPage from "./components/pages/NetStoreProduct/NetStoreProduct"
import NetStoreUpdateProductPage from "./components/pages/NetStoreUpdateProduct/NetStoreUpdateProduct"

function App() {
  return (
    <BrowserRouter> 
      <Routes>
        <Route path="/">
          <Route path="" element={<WelcomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="callback" element={<CallbackPage />} />
          <Route path="logout" element={<LogoutPage />} />
        </Route>

        <Route path="/workers">
          <Route path="manage">
            {/* <Route path="" element={<WorkersHomePage />} /> */}
            <Route path="" element={<h1 className="text-white p-4">
              Тут будет выбор действия:<br/>просмотр сотрудников, добавить сотрудника и тд
            </h1>} />
            <Route path="staff-list" element={<StaffListPage />} />
            <Route path="add-worker" element={<AddWorkerPage />} />
          </Route>
        </Route>

        <Route path="/portfolio">
          <Route path="home" element={<PortfolioHomePage />} />

          <Route path="projects">
            <Route path="" element={<PortfolioProjectsPage />} />
            <Route path="create" element={<PortfolioCreateProjectPage />} />
            <Route path="edit/:id" element={<PortfolioEditProjectPage />} />
          </Route>

          <Route path="exhibitions">
            <Route path="" element={<PortfolioExhibitionsPage />} />
            <Route path="create" element={<PortfolioCreateExhibitionPage />} />
            <Route path="edit/:id" element={<PortfolioEditExhibitionPage />} />
          </Route>

        </Route>

        <Route path="/store">
          <Route path="home" element={<NetStoreHomePage />} />
          
          <Route path="orders">
            <Route path="" element={<NetStoreOrdersPage />} />
            <Route path=":id" element={<NetStoreOrderPage />} />
          </Route>

          <Route path="products">
            <Route path="" element={<NetStoreProductsPage />} />
            <Route path="popular" element={<NetStorePopProductsPage />} />
            <Route path="create" element={<NetStoreCreateProductPage />} />
            <Route path=":id" element={<NetStoreProductPage />} />
            <Route path="update/:id" element={<NetStoreUpdateProductPage />} />
          </Route>

          <Route path="categories">
            <Route path="" element={<NetStoreCategoriesPage />} />
            <Route path="create" element={<NetStoreCreateCategoryPage />} />
            <Route path="update/:id" element={<NetStoreUpdateCategoryPage />} />
          </Route>
        </Route>

        <Route path="/dev">
          <Route path="home" element={<ForDevHomePage />} />
          <Route path="users" element={<ForDevUsersPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
