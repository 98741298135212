import "bootstrap/dist/css/bootstrap.min.css"
import "./NetStoreProducts.css"
import { AxiosResponse } from "axios"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { api } from "../../../api"
import { IStoreCategory, IStoreProductMin } from "../../../types"
import { QUERY_LIMIT } from "../../../constants"

const NetStoreProductsPage = () => {
  const navigate = useNavigate()
  const [products, setProducts] = useState<null | IStoreProductMin[]>(null)
  const [categories, setCategories] = useState<null | IStoreCategory[]>(null) // Temp
  const [query, setQuery] = useState("")
  const queryOffsetRef = useRef(0)

  const fetchProducts = () => {
    api
      .get("internet_store/products", {
        params: {
          [query != "" ? "vendor_code_filter" : ""]: query,
          offset: queryOffsetRef.current,
          limit: QUERY_LIMIT
        }
      })
      .then((res: AxiosResponse<IStoreProductMin[]>) => {
        setProducts(res.data)
      })
  }

  // Temp
  const fetchCategories = () => {
    api
      .get("internet_store/categories")
      .then((response: AxiosResponse<IStoreCategory[]>) => {
        setCategories(response.data)
      })
      .catch((err) => {
        toast.error("Не удалось загрузить список категорий. Соответствующая колонка в таблице будет пустой.")
        console.error(err)
      })
  }

  useEffect(() => {
    fetchProducts()
    fetchCategories()
  }, [])

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    fetchProducts()
  }

  if (!products) return <h1 className="text-white text-center mt-5">Загрузка...</h1>

  return (
    <div className="ns_products__body">
      <header className="ns_products__header">
        <img src="/img/logo.svg" alt="Image" className="ns_products__logo" />
        <button type="button" className="btn btn-primary"
          onClick={() => navigate("/store/home")}>Назад</button>
      </header>
      <main className="ns_products__main">
        <div className="ns_products__main-buttons">
          <button type="button" onClick={() => navigate("/store/products/popular")}
            className="btn btn-light">Популярные продукты</button>
          <button type="button" onClick={() => navigate("/store/products/create")}
            className="btn btn-light">Создать продукт</button>
        </div>
        <form className="ns_products__search" onSubmit={(e) => handleSearch(e)}>
          <div className="input-group">
            <input
              className="form-control"
              type="search"
              placeholder="Артикул"
              aria-label="Search"
              name="vendor_code_filter"
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
            <button className="btn btn-primary">Искать</button>
          </div>
        </form>
        <div className="ns_products__main-table">
          <table className="table">
            <thead>
              <tr>
                <th>Название</th>
                <th>Наличие</th>
                <th>Артикул</th>
                { categories && <th>Категория</th> }
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <tr key={`product_${product.id}`}>
                  <td>{product.title}</td>
                  <td>{product.is_available ? "Да" : "Нет"}</td>
                  <td>{product.vendor_code}</td>
                  { categories && (
                    <td> {categories.find(c => c.id === product.category_id)?.title || "???"} </td>
                  )}
                  <td>
                    <button
                      onClick={() => navigate(`/store/products/${product.id}`)}
                      className="btn btn-primary"
                    >Подробнее</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {
            products?.length === 0 && (
              <h2 className="text-center pb-3">Пусто</h2>
            )
          }
        </div>
        <div className="ns_products__footer-buttons">
          {
            queryOffsetRef.current !== 0 && (
              <button type="button" className="btn btn-primary"
              onClick={() => {
                queryOffsetRef.current -= QUERY_LIMIT
                fetchProducts()
              }}>Предыдущая</button>
            )
          }
          
          <button type="button" className="btn btn-primary"
            onClick={() => {
              queryOffsetRef.current += QUERY_LIMIT
              fetchProducts()
            }}>Следующая</button>
        </div>
      </main>
    </div>
  )
}

export default NetStoreProductsPage
