import { useNavigate } from "react-router-dom"
import "./NetStoreCreateCategory.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { api } from "../../../api"
import { toast } from "sonner"
import { IStoreCategory } from "../../../types"

const NetStoreCreateCategoryPage = () => {
  const navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    
    const data = {
      title: formData.get("categoryTitle"),
      significant_flag: JSON.parse(formData.get("isSignificant") as string)
    }

    // Создание категории
    try {
      await api.post("internet_store/categories", data)
      toast.success("Категория добавлена")
      
      // Поиск id только-что созданной категории
      const allCategories = (await api.get<IStoreCategory[]>("internet_store/categories")).data
      const createdCategoryId = allCategories.find(c => c.title === formData.get("categoryTitle"))?.id
      
      // На всякий
      if (!createdCategoryId) {
        console.error("Попытка запросить все категории и найти ту, что с называнием как в форме - провалилась")
        toast.error("Не удалось добавить фотографии в выставку")
        return
      }
      
      // Загрузка фото в только-что созданную категорию
      try {
        await api.postForm(`internet_store/categories/${createdCategoryId}/upload_image`, {
          file: formData.get("catergoryImage")
        })
        toast.success("Фотография добавлена")
        form.reset()
        navigate("/store/categories")
      } catch(err) {
        console.error(err)
        toast.error("Не удалось добавить фотографию")
      }

    } catch(err) {
      console.error(err)
      toast.error("Не удалось создать категорию")
    }
  }

  return (
    <div className="ns_cteate_cat__body">
      <header className="ns_categories__header">
        <img src="/img/logo.svg" alt="Image" className="ns_categories__logo" />
        <button type="button" className="btn btn-primary" onClick={() => navigate("/store/categories")}>
          Назад
        </button>
      </header>

      <main className="container-sm pt-5">
        <div className="card">
          <div className="card-header ns_create_cat__title">Добавить категорию</div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <label htmlFor="categoryTitle" className="form-label">Название</label>
              <input type="text" className="form-control" id="categoryTitle" name="categoryTitle" defaultValue="" required />

              <label className="form-label">Отображается на главной странице</label><br />
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="isSignificant" id="inlineRadio1" value={"true"}
                  defaultChecked={true} />
                <label className="form-check-label" htmlFor="inlineRadio1">Да</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="isSignificant" id="inlineRadio2" value={"false"}
                  defaultChecked={false} />
                <label className="form-check-label" htmlFor="inlineRadio2">Нет</label>
              </div><br />

              <div>
                <label htmlFor="catergoryImage" className="form-label">Фотография категории</label>
                <input className="form-control" type="file" name="catergoryImage" id="catergoryImage" required />
              </div>
              
              <button type="submit" className="btn btn-primary ns_create_cat__button">Создать</button>
            </form>
          </div>
        </div>
        <div className="ns_create_cat-message" id="hider-message">
          {/*
                  Some Message
                  <button id="hider"><img src="/img/exit.png" alt="Image" /></button>
                */}
        </div>
      </main>
    </div>
  )
}

export default NetStoreCreateCategoryPage
