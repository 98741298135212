import { useNavigate } from "react-router-dom"
import "./NetStoreOrders.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useRef, useState } from "react"
import { IStoreOrderMin } from "../../../types"
import { api } from "../../../api"

const NetStoreOrdersPage = () => {
  const navigate = useNavigate()
  const statusRef = useRef<HTMLSelectElement>(null)
  const limitRef = useRef<HTMLSelectElement>(null)
  const offsetRef = useRef<number>(0)
  const [orders, setOrders] = useState<IStoreOrderMin[] | null>(null)

  const fetchOrders = async () => {
    const response = await api.get<IStoreOrderMin[]>("internet_store/orders", {
      params: {
        limit: limitRef.current?.value,
        status: statusRef.current?.value,
        offset: Math.max(0, offsetRef.current),
      }
    })
    setOrders(response.data)
  }

  useEffect(() => {
    fetchOrders()
  }, [])

	return (
    <div className="ns_orders__body">
      <header className="ns_orders__header">
        <img src="/img/logo.svg" alt="Image" className="ns_orders__logo"/>
        <button type="button" className="btn btn-primary"
          onClick={() => navigate("/store/home")}>Назад</button>
      </header>
      <main className="ns_orders__main">
        <div>
          <div className="ns_orders__main-select">
            <select
              ref={statusRef}
              className="form-select"
              aria-label="Статус заказа"
              name="status"
              id="filter"
              defaultValue="в ожидании"
              onChange={() => {
                fetchOrders()
              }}
            >
              <option value="null">все</option>
              <option value="в ожидании">В ожидании</option>
              <option value="в процессе">В процессе</option>
              <option value="подтверждён">Подтверждён</option>
              <option value="отменён">Отменён</option>
              <option value="выполнен">Выполнен</option>
            </select>

            <select
              ref={limitRef}
              className="form-select"
              aria-label="Количество на странице"
              name="limit"
              defaultValue="20"
              onChange={() => {
                offsetRef.current = 0
                fetchOrders()
              }}
            >
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div className="ns_orders__main-table">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Номер</th>
                <th className="text-center">Время</th>
                <th className="text-center">Статус</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                orders?.map((order) => (
                  <tr key={`order-${order.id}`}>
                    <td className="text-center">
                      {order.id}
                    </td>
                    <td id="time" className="text-center">
                      {order.time}
                    </td>
                    <td className="text-center">
                      {order.status}
                    </td>
                    <td>
                      <button
                        onClick={() => navigate(`/store/orders/${order.id}`)}
                        className="btn btn-primary d-block mx-auto"
                      >
                        Просмотреть
                      </button>
                    </td>
                  </tr>
                ))
              }

            </tbody>
          </table>
          {(orders === null) && <h1 className="text-black text-center pt-3 pb-4">Загрузка...</h1>}
          {(orders?.length === 0) && <h1 className="text-black text-center fs-3 pt-3 pb-4">Заказов нету</h1>}
        </div>

        <div className="ns_orders__main-buttons">
          {
            offsetRef.current !== 0  && (
              <button type="button" className="btn btn-primary"
                onClick={() => {
                  offsetRef.current -= (+limitRef.current!.value as number)
                  fetchOrders()
                }}
              >
                Предыдущая
              </button>
            )
          }

          {
            orders && orders?.length !== 0 && (
              <button type="button" className="btn btn-primary ms-auto"
              onClick={() => {
                offsetRef.current += (+limitRef.current!.value as number)
                fetchOrders()
              }}
            >
              Следующая
            </button>
            )
          }
        </div>
        <div className="ns_orders__main-message" id="hider-message">
        </div>
      </main>
    </div>
	)
}

export default NetStoreOrdersPage
