import axios from "axios";

export const api = axios.create({
  // baseURL: "https://api-dashboard.decoratoria.ru/api/v1/"
  baseURL: "https://gateway.decoratoria.ru/dashboard_api"
})

// Send current access_token from the LS with every request
api.interceptors.request.use((config) => {
  config.headers!['Authorization'] =  `Bearer ${localStorage.getItem("access-token")}`

  return config
})

// Intercept 401 responses and exchange refresh token for new pair 
api.interceptors.response.use(r => r, (error) => {
  const originalRequest = error.config // to later try send it again with the new access token

  if (error.response?.status === 401) {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('client_id', 'general_dashboard_client');
    params.append('refresh_token', localStorage.getItem("refresh-token") || "null");
    
    axios
      .post("https://sso.decoratoria.ru/realms/sso_decoratoria/protocol/openid-connect/token", 
        params, 
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
        }
      )
      .then(response => {
        console.log("Expired access_token successfully exchanged for the new pair")
        localStorage.setItem("access-token", response.data.access_token)
        localStorage.setItem("refresh-token", response.data.refresh_token)
        
        originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`
        axios(originalRequest) // sending saved request with new access_token
      })
      .catch((err) => {
        console.error("Can't exchange expired access_token for the new pair\nPresumably, refresh_token is also expired or doesn't exist")
        console.error(err.message)
      })
  }
  
  return Promise.reject(error)
})