
import { useState } from "react"
import { Role } from "../types"
import { jwtDecode } from "jwt-decode"
import { toast } from "sonner"

/**
 * @description This hook decodes stored access token and returns the role user assigned to
 * @returns "ADMIN" | "WORKER" | "USER" 
 * */ 
export const useUserRole = (): Role => {
  const [userRole] = useState<Role>(getCurrentRole)

  function getCurrentRole():Role {
    const token = localStorage.getItem("access-token")
    if (!token) return "USER"
    
    const decoded = jwtDecode<any>(token)

    if ((decoded.exp - (Date.now() / 1000)) <= 0) {
      console.log("EXPIRED!");
      
      toast.message("Сессия истекла, пожалуйста авторизуйтесь")
      localStorage.removeItem("access-token")
      return "USER"
    } 
    // console.log("decoded: \n", decoded);
    
    const roles: string[] = decoded?.roles
    
    if (roles?.includes("admin/dev")) return "ADMIN"
    if (roles?.includes("worker")) return "WORKER"
    
    return "USER"
  }

  return userRole
}