import "bootstrap/dist/css/bootstrap.min.css"
import { useNavigate } from "react-router-dom"
import { useUserRole } from "../../../hooks/useUserRole"
import { withRole } from "../../../hocs/withRole"
import { useEffect } from "react"
import { toast } from "sonner"

const HomePage = () => {
  const navigate = useNavigate()
  const role = useUserRole()

  const HomeLayout = withRole(role, {
    ForAdmin: AdminHomeLayout,
    ForWorker: WorkerHomeLayout,
  })

  useEffect(() => {
    if (role === "USER") {
      toast.error("Недостаточно прав")
      navigate("/")
    }
  }, [])

	return (
		<div>
      <header className="ns_categories__header">
        <img src="/img/logo.svg" alt="Image" className="ns_categories__logo"/>
      </header>
      <main className="container">
        <HomeLayout />
      </main>
		</div>
	)
}

export default HomePage


function AdminHomeLayout() {
  const navigate = useNavigate()

  return <>
    <div className={`row text-center gap-3 pt-5`}>
      <button className="col btn btn-light py-3 fs-5 opacity-75" onClick={() => toast.message("Эта страница пока в разработке...")}>
      {/* <button className="col btn btn-light py-3 fs-5" onClick={() => navigate("/workers/manage")}> */}
        Управление работниками
      </button>
      <button className="col btn btn-light py-3 fs-5" onClick={() => navigate("/store/home")}>
        Управление интернет магазином
      </button>
      <button className="col btn btn-light py-3 fs-5" onClick={() => navigate("/portfolio/home")}>
        Управление портфолио
      </button>
    </div>

    <div className={`row text-center gap-3 pt-5 justify-content-center`}>
      <button className="col btn btn-light py-3 px-5 fs-5 flex-grow-0" onClick={() => navigate("/dev/home")}>
        Для администраторов
      </button>
    </div>
  </>
}

function WorkerHomeLayout() {
  const navigate = useNavigate()

  return <>
    <div className={`row text-center gap-3 pt-5`}>
      <button className="col btn btn-light py-3 fs-5" disabled onClick={() => navigate("/workers-")}>
        Личный кабинет работника<br/>
        (в разработке)
      </button>
    </div>
  </>
}
