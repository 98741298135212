import { useEffect, useState } from "react"
import "./NetStorePopProducts.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { IStorePopProduct } from "../../../types"
import { api } from "../../../api"
import { toast } from "sonner"
import { AxiosResponse } from "axios"
import { useNavigate } from "react-router-dom"

const NetStorePopProductsPage = () => {
  const navigate = useNavigate()
  const [popProducts, setPopProducts] = useState<null | IStorePopProduct[]>(null)

  const fetchProducts = () => {
    api
      .get("internet_store/products/pop_products")
      .then((res: AxiosResponse<IStorePopProduct[]>) => {
        setPopProducts(res.data)
      })
      .catch((err) => {
        toast.error("Не получилось запросить популярные продукты")
        console.error(err)
      })
  }

  const handleDeletePopular = (product: IStorePopProduct) => {
    if (!window.confirm(`Убрать продукт "${product.product.title}" из группы популярных?`)) return
    api
      .delete(`internet_store/products/pop_products`, {
        params: {
          product_id: product.id
        }
      })
      .then(() => {
        toast.success(`Продукт "${product.product.title}" убран из группы популярных продуктов`)
        fetchProducts()
      })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  if (!popProducts) return <h1 className="text-white text-center mt-5">Загрузка...</h1>

  return (
    <div>
    <header className="ns_home__header">
      <img src="/img/logo.svg" alt="Image" className="ns_home__logo"/>
      <button type="button" className="btn btn-primary"
        onClick={() => navigate("/store/products")}>Назад</button>
    </header>
      <main className="ns_pop-products__main">
        <div className="ns_pop-products__main-table">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Название</th>
                <th className="text-center">Картинка</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                popProducts.map(product => (
                  <tr key={`product_${product.id}`}>
                    <td className="text-center">{ product.product.title }</td>
                    <td>
                      <img
                        className="d-block mx-auto"
                        src={product?.product?.images[0]?.link_path || "/img/placeholder.jpg" }
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeletePopular(product)}
                      >Убрать</button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            popProducts?.length === 0 && (
              <h2 className="text-center fw-bold pb-3">Пусто..</h2>
            )
          }
        </div>
      </main>
    </div>
  )
}

export default NetStorePopProductsPage
