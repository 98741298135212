import { useNavigate } from "react-router-dom"
import "./NetStoreCreateProduct.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useState } from "react"
import { IStoreCategory, IStoreProductMin } from "../../../types"
import { api } from "../../../api"
import { AxiosResponse } from "axios"
import { toast } from "sonner"

const NetStoreCreateProductPage = () => {
  const navigate = useNavigate()

  const [categories, setCategories] = useState<null | IStoreCategory[]>(null)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)

    const data = {
      title: formData.get("title"),
      category_id: formData.get("category_id"),
      price: formData.get("price"),
      description: formData.get("description"),
      size: formData.get("size"),
      vendor_code: formData.get("vendor_code"),
      is_available: formData.get("is_available"),
      weight: formData.get("weight")?.toString().trim() || null,
      term_production: formData.get("term_production")?.toString().trim() || null,
      material: formData.get("material")?.toString().trim() || null,
      colors: formData.get("colors")?.toString().trim().split(", ")
    }

    if (!data.category_id || data.category_id === "invalid") {
      toast.error("Категория не выбрана!")
      return
    }

    const designFile = formData.get("design") as File
    const imageFiles = formData.getAll("images") as File[]

    try {
      const productResponse = await api
        .post<{product_id: null | number}>("internet_store/products", data)
      
      const createdProductId = productResponse.data.product_id
      if (!createdProductId) {
        // It will be triggered even if specified name or vendor's code exists among DELTED products.
        toast.error("Не удалось создать продукт. Удостоверьтесь, что название и артикул уникальны!")
        return
      }
      
      toast.success("Продукт успешно создан")
      
      // At least one image is uploaded
      if (imageFiles?.[0]?.size !== 0) {
        await api.postForm(`internet_store/products/${createdProductId}/upload_images`, {
          files: imageFiles
        })
        toast.success("Фотографии успешно загружены")
      }
      
       // Design file is uploaded
      if (designFile?.size !== 0) {
        await api.postForm(`internet_store/products/${createdProductId}/upload_design`, {
          file: designFile
        })
        toast.success("Чертёж успешно загружен")
      }

      navigate(`/store/products/${createdProductId}`)
      
    } catch(err) {
      if (err?.config?.method === "post" && err?.config?.url === "internet_store/products") {
        toast.error("Не удалось создать продукт")
        console.error(err.message, err)
      }
      else if (err?.config?.method === "get" && err?.config?.url === "internet_store/products") {
        toast.error("Не удалось запросить продукты")
        console.error(err.message, err)
      }
      else if (err?.config?.url?.includes("/upload_images")) {
        toast.error("Продукт создан, но фотографии добавить не удалось")
        console.error(err.message, err)
      }
      else if (err?.config?.url?.includes("/upload_design")) {
        toast.error("Продукт создан, но чертёж добавить не удалось")
        console.error(err.message, err)
      }
      else {
        toast.error("Что-то пошло не так при создании продукта или добавлении фотографий")
        console.error(err.message, err)
      }
    }
  }

  const fetchCategories = () => {
    api
      .get("internet_store/categories")
      .then((response: AxiosResponse<IStoreCategory[]>) => {
        setCategories(response.data)
    })
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  if (!categories) return <h1 className="text-white text-center mt-5">Загрузка...</h1>

  return (
    <div className="ns_create_product__body">
      <header className="ns_create_product__header">
        <img src="/img/logo.svg" alt="Image" className="ns_create_product__logo" />
        <button type="button" className="btn btn-primary"
          onClick={() => navigate("/store/products")}>Назад</button>
      </header>

      <main className="ns_create_product__main">
        <div className="card">
          <div className="card-header ns_create_product__title">Создать продукт</div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <label htmlFor="title" className="form-label">Название</label>
              <input type="text" className="form-control" id="title" name="title" defaultValue="" required />
              <label htmlFor="price" className="form-label">Цена</label>
              <input type="number" className="form-control" id="price" name="price" defaultValue="" required />
              <label htmlFor="description" className="form-label">Описание</label>
              <textarea className="form-control" id="description" name="description" defaultValue="" required />
              <label htmlFor="size" className="form-label">Размер</label>
              <input type="text" className="form-control" id="size" name="size" defaultValue="" required />
              <label className="form-label">Наличие</label><br />
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="is_available" id="inlineRadio1" value="true" required />
                <label className="form-check-label" htmlFor="inlineRadio1">Да</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="is_available" id="inlineRadio2" value="false" />
                <label className="form-check-label" htmlFor="inlineRadio2">Нет</label>
              </div><br />
              <label htmlFor="code" className="form-label">Артикул</label>
              <input type="text" className="form-control" id="code" name="vendor_code" defaultValue="" required />
              <label htmlFor="weight" className="form-label">Вес</label>
              <input type="number" className="form-control" step="0.1" id="weight" name="weight" defaultValue="" />
              <label htmlFor="term_production" className="form-label">Срок производства</label>
              <input type="number" className="form-control" id="term_production" name="term_production" defaultValue="" required />
              <label htmlFor="material" className="form-label">Материал</label>
              <input type="text" className="form-control" id="material" name="material" defaultValue="" />
              <label htmlFor="category" className="form-label">Категория</label>
              <select className="form-select" aria-label="Статус заказа" name="category_id" id="category" defaultValue="invalid">
                <option disabled hidden value="invalid">Выбирите статус...</option>
                {
                  categories.map(category => (
                    <option key={`category_${category.id}`} value={ category.id}>{ category.title }</option>
                  ))
                }
              </select>
              <label htmlFor="colors" className="form-label">Цвета</label>
              <input type="text" className="form-control" id="colors" name="colors" defaultValue="" placeholder="Через запятую и пробел. Например: красный, синий" required />
              <label htmlFor="design" className="form-label">Чертеж</label>
              <input type="file" className="form-control" id="design" name="design" />
              <label htmlFor="images" className="form-label">Фотографии</label>
              <input type="file" multiple className="form-control" id="images" name="images" />
              <button type="submit" className="btn btn-primary ns_create_product__button">Создать</button>
            </form>
          </div>
        </div>
      </main>
    </div>
  )
}

export default NetStoreCreateProductPage
