import "./AddWorker.css"
import "bootstrap/dist/css/bootstrap.min.css"

const AddWorkerPage = () => {
	return (
  <div className="add_worker__body">
    <header className="add_worker__header">
      <img src="/img/logo.svg" alt="Image" className="add_worker__logo"/>
    </header>
    <main className="add_worker__main">
    <div className="add_worker__main-message" id="hider-message">
      {/*
        Some Message
        <button id="hider"><img src="/img/exit.png" alt="Image" /></button>
      */}
      </div>
      <div className="card">
        <div className="card-header add_worker__title">Добавить работника</div>
        <div className="card-body">
          <form action="{{url_for('staff.create_worker')}}" method="post">
            <label htmlFor="name" className="form-label">Ф.И.О.</label>
            <input type="text" className="form-control" id="name" name="name" defaultValue=""/>
            <label htmlFor="login" className="form-label">Логин</label>
            <input type="text" className="form-control" id="login" name="login" defaultValue="" required/>
            <label htmlFor="password" className="form-label">Пароль</label>
            <input type="password" className="form-control" id="password" name="password" defaultValue="" required/>
            <label htmlFor="position" className="form-label">Должность</label>
            <input type="text" className="form-control" id="position" name="position" defaultValue=""/>
            <label htmlFor="telephone_number" className="form-label">Номер телефона</label>
            <input type="number" className="form-control" id="telephone_number" name="telephone_number" defaultValue=""/>
            <label htmlFor="pay" className="form-label">ЗП</label>
            <input type="number" className="form-control" id="pay" name="pay" defaultValue=""/>
            <label htmlFor="description" className="form-label">Описание</label>
            <input type="text" className="form-control" id="description" name="description" defaultValue=""/>
            <label htmlFor="started" className="form-label">Начало работы</label>
            <input type="date" className="form-control add_worker__input-date" id="started" name="started" defaultValue=""/>
            <div className="row">
              <div className="col">
                <select className="form-select" name="role">
                  <option selected value="Админ">Админ</option>
                  <option value="Работник">Работник</option>
                </select>
              </div>
              <div className="col">
                <button type="submit" className="btn btn-primary">Добавить работника</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
	)
}

export default AddWorkerPage
