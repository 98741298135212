import { useEffect } from "react"
import "./Logout.css"
import "bootstrap/dist/css/bootstrap.min.css"
import axios from "axios"

const LogoutPage = () => {
  useEffect(() => {
    console.log("Logging out...");
    
    // const callback_uri = encodeURIComponent(`${window.location.origin}`)
    
    // axios.post(`https://sso.decoratoria.ru/realms/sso_decoratoria/protocol/openid-connect/logout`)
    
    const params = new URLSearchParams();
    params.append('client_id', 'general_dashboard_client');
    params.append('refresh_token', localStorage.getItem("refresh-token") || "invalid");
    
    axios
      .post(`https://sso.decoratoria.ru/realms/sso_decoratoria/protocol/openid-connect/logout`, 
      params, 
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
      }
      )
      .finally(() => {
        console.log("Tokens removed from ls");
        
        localStorage.removeItem("access-token")
        localStorage.removeItem("refresh-token")
        window.location.href = window.location.origin
      })
    
  }, [])
	return (
		<div>
			
		</div>
	)
}

export default LogoutPage
