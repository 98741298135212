import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { api } from "../../../api"
import { IExhibition } from "../../../types"
import "./PortfolioEditExhibition.css"
import "bootstrap/dist/css/bootstrap.min.css"

const PortfolioEditExhibitionPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [thisExhibition, setThisExhibition] = useState<IExhibition | null>(null)

  useEffect(() => {
    api.get<IExhibition>(`/portfolio/exhibitions/${id}`)
      .then(response => {
        setThisExhibition(response.data)
      })
  }, [])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.target as HTMLFormElement
    const formData = new FormData(form)

    const data = {
      description: formData.get("description") || "",
    }

    try {
      const response = await api.patch(`/portfolio/exhibitions/${id}`, data)
      toast.success(response.data?.message || "Выставка обновлена")
      navigate("/portfolio/exhibitions")
    } catch (err: any) {
      console.error(err.message)
      toast.error("Не удалось обновить выставку")
    }
  }

	return (
		<>
      <header className="ns_categories__header">
        <img src="/img/logo.svg" alt="Image" className="ns_categories__logo"/>
        <button type="button" className="btn btn-primary" onClick={() => navigate("/portfolio/exhibitions")}>
          Назад
        </button>
      </header>
      <div className="container py-5">
      <form className="card" onSubmit={handleSubmit}>
          <div className="card-header">Редактировать выставку "{thisExhibition?.name}"</div>
          <div className="card-body">

            <div className="mb-3">
              <label htmlFor="description" className="form-label">Описание</label>
              <input
                type="text"
                className="form-control"
                name="description"
                id="description"
                defaultValue={thisExhibition?.description}
              />
            </div>
            
            <button type="submit" className="btn btn-primary">Редактировать</button>
          </div>
        </form>
      </div>
		</>
	)
}

export default PortfolioEditExhibitionPage
