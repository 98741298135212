import { Role } from "../types"

interface IProps {
  (role: Role,
    variants: {
      ForAdmin?: React.FC,
      ForWorker?: React.FC,
      ForUser?: React.FC
  }): React.FC
}

export const withRole: IProps = (role, {ForAdmin, ForWorker, ForUser}) => {
  return function Wrapper(props: any) {
    if (role === "ADMIN") return (ForAdmin && <ForAdmin {...props} />) || null
    if (role === "WORKER") return (ForWorker && <ForWorker {...props} />) || null
    if (role === "USER") return (ForUser && <ForUser {...props} />) || null
  }
}