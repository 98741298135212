import { useNavigate } from "react-router-dom"
import "./NetStoreCategories.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useState } from "react"
import { AxiosResponse } from "axios"
import { api } from "../../../api"
import { IStoreCategory } from "../../../types"
import { toast } from "sonner"

const NetStoreCategoriesPage = () => {
  const navigate = useNavigate()
  const [categories, setCategories] = useState<IStoreCategory[] | null>(null)
  const [onlySignificant, setOnlySignificant] = useState<boolean>(false)

  const fetchCategories = () => {
    api
      .get("internet_store/categories")
      .then((response: AxiosResponse<IStoreCategory[]>) => {
        setCategories(response.data)
      })
  }

  const handleDelete = (category: IStoreCategory) => {
    if (!window.confirm(`Удалить категорию "${category.title}"?`)) return
    api
      .delete(`internet_store/categories/${category.id}`)
      .then(() => {
        toast.success(`Категория "${category.title}" удалена`)
        fetchCategories()
      })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  if (!categories) return <h1 className="text-white text-center mt-5">Загрузка...</h1>// <Loader/>

  return (
    <div className="ns_categories__body">
      <header className="ns_categories__header">
        <img src="/img/logo.svg" alt="Image" className="ns_categories__logo" />
        <button type="button" className="btn btn-primary" onClick={() => navigate("/store/home")}>
          Назад
        </button>
      </header>
      <main className="ns_categories__main">
        <div className="ns_categories__main-buttons">

        <div className="btn-group" role="group">
          <input
            type="checkbox"
            className="btn-check"
            id="only_significant"
            autoComplete="off"
            onChange={() => setOnlySignificant((p) => !p)}
            checked={onlySignificant}
          />
          <label className="btn btn-outline-light" htmlFor="only_significant">
            Только категории на главной
          </label>
        </div>

          <button
            type="button"
            onClick={() => navigate("/store/categories/create")}
            className="btn btn-light"
          > Создать категорию </button>

        </div>
        <div className="ns_categories__main-table">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Название</th>
                <th className="text-center">На домашней странице</th>
                <th className="text-center">Картинка</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                categories
                  .filter((category: IStoreCategory) => onlySignificant ? category.significant_flag : true)
                  .map((category: IStoreCategory) => (
                    <tr key={`category_${category.id}`}>
                      <td className="text-center fs-4">
                        {category.title}
                      </td>
                      <td className="text-center fs-4">
                        {category.significant_flag ? "Да" : "Нет"}
                      </td>
                      <td>
                        <img
                          src={category.image?.link_path || "/img/placeholder.jpg"}
                          className="d-block mx-auto"
                        />
                      </td>
                      <td>
                        <div className="ns_categories__control-buttons">
                          <button
                            type="button"
                            onClick={() => navigate(`/store/categories/update/${category.id}`)}
                            className="btn btn-primary"
                          >Обновить</button>
                          <button
                            type="button"
                            onClick={() => handleDelete(category)}
                            className="btn btn-danger"
                          >Удалить</button>
                        </div>
                      </td>
                    </tr>
                  ))
              }

              {
                categories
                  .filter((category: IStoreCategory) => onlySignificant ? category.significant_flag : true)
                  ?.length == 0 && (
                    <tr>
                      <td/>
                      <div className="text-center fs-5 fw-bold p-3">Пусто..</div>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>
        <div className="ns_categories__main-message" id="hider-message">
          {/*
                Some Message
                <button id="hider"><img src="/img/exit.png" alt="Image" /></button>
              */}
        </div>
      </main>
    </div>
  )
}

export default NetStoreCategoriesPage
