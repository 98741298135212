import "./Welcome.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { generateCodeChallenge, generateCodeVerifier } from "../../../helpers/authCodeFlow"
import { Link, useNavigate } from "react-router-dom"

const WelcomePage = () => {
  const navigate = useNavigate()

  const handleLogIn = () => {
    const cv = generateCodeVerifier()
    const cc = generateCodeChallenge(cv)
    
    const callback_uri = encodeURIComponent(`${window.location.origin}/callback`)
    
    localStorage.removeItem("code-verifier")
    localStorage.setItem("code-verifier", cv)
    window.location.replace(`https://sso.decoratoria.ru/realms/sso_decoratoria/protocol/openid-connect/auth?client_id=general_dashboard_client&response_type=code&code_challenge=${cc}&code_challenge_method=S256&redirect_uri=${callback_uri}`)
  }

	return (
		<div className="home__body">
      <header className="home__header">
        <Link to="/home">
          <img src="/img/logo.svg" alt="Image" className="home__logo"/>
        </Link>

        {
          localStorage.getItem("access-token")
            ?
          <button type="button" className="home__button" onClick={() => navigate("/logout") }>Logout</button>
            :
          <button type="button" className="home__button" onClick={ handleLogIn }>Login</button>
        }

      </header>
      <main className="home__main">
        <img src="/img/stroitel.jpg" alt="Image" className="home__image"/>
      </main>
    </div>
	)
}

export default WelcomePage
