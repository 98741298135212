import { useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"

const PortfolioHomePage = () => {
  const navigate = useNavigate()
  
	return (
		<div>
      <header className="ns_categories__header">
        <img src="/img/logo.svg" alt="Image" className="ns_categories__logo"/>
        <button type="button" className="btn btn-primary" onClick={() => navigate("/home")}>
          Назад
        </button>
      </header>

      <main className="container py-5">
        <div className="row gap-3 text-center">
          <button className="col btn btn-light py-3 fs-4" onClick={() => navigate("/portfolio/projects")}>
            Проекты
          </button>
          <button className="col btn btn-light py-3 fs-4" onClick={() => navigate("/portfolio/exhibitions")}>
            Выставки
          </button>
        </div>
      </main>

		</div>
	)
}

export default PortfolioHomePage
