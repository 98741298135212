import { useNavigate } from "react-router-dom"
import "./NetStoreHome.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useState } from "react"
import { IStoreStats } from "../../../types"
import { api } from "../../../api"
import { AxiosResponse } from "axios"

const NetStoreHomePage = () => {
  const navigate = useNavigate()
  const [stats, setStats] = useState<IStoreStats | null>(null)
  const [earingsPeriod, setEaringsPeriod] = useState<"for_day" | "for_month">("for_month")

  useEffect(() => {
    api
      .get("internet_store/statistic/statistic", {
        params: {
          [earingsPeriod]: true
        }
      })
      .then((response: AxiosResponse<IStoreStats>) => {
        setStats(response.data)
      })
  }, [earingsPeriod])

  const handleEaringsRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEaringsPeriod(e.target.id as "for_day" | "for_month")
  }

  if (!stats) return <h1 className="text-white text-center mt-5">Загрузка...</h1>

  return (
    <div className="ns_home__body">
      <header className="ns_home__header">
        <img src="/img/logo.svg" alt="Image" className="ns_home__logo" />
        <button type="button" className="btn btn-primary"
          onClick={() => navigate("/home")}>Назад</button>
      </header>
      <main className="ns_home__main">
        <div className="row ns_home__main-row1 text-center">
          <div className="col ns_home__main-count-users">
            <p><strong>Количество пользователей:</strong></p>
            <p className="ns_home__main-count-users-count">{stats.count_users}</p>
          </div>
          <div className="col ns_home__main-form">
            <div>
              <legend><strong>Доходность</strong></legend>
              <div className="form-check form-check-inline">
                <input className="form-check-input"
                  type="checkbox"
                  onChange={handleEaringsRange}
                  checked={earingsPeriod === "for_day"}
                  id="for_day"
                  name="for_day"
                />
                <label className="form-check-label" htmlFor="for_day">За день</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input"
                  type="checkbox"
                  onChange={handleEaringsRange}
                  checked={earingsPeriod === "for_month"}
                  id="for_month"
                  name="for_month"
                />
                <label className="form-check-label" htmlFor="for_month">За текущий месяц</label>
              </div>
            </div>
            <div id="ns_home__main-form-all">{stats.earnings} руб.</div>
          </div>
          <div className="col ns_home__main-products">
            <strong>Популярные продукты:</strong>
            <center>
              <div className="mt-4 fs-3">
                {
                  stats.pop_products.map(p => (
                    <center>{p.title}</center>
                  ))
                }
                {
                  stats.pop_products.length === 0 && (<h5>Информации нету</h5>)
                }
              </div>
            </center>
          </div>
        </div>
        <div className="row ns_home__main-row2">
          <button className="btn btn-light col" onClick={() => navigate("/store/categories")}>
            <div><strong>Управление категориями</strong></div>
            <img src="/img/image3.png" alt="Image" />
          </button>
          <button className="btn btn-light col" onClick={() => navigate("/store/products")}>
            <div><strong>Управление продуктами</strong></div>
            <img src="/img/image2.png" alt="Image" />
          </button>
          <button className="btn btn-light col" onClick={() => navigate("/store/orders")}>
            <div><strong>Заказы</strong></div>
            <img src="/img/image4.png" alt="Image" />
          </button>
        </div>
      </main>
    </div>
  )
}

export default NetStoreHomePage
