import { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { api } from "../../../api"
import { AxiosResponse } from "axios"
import { IResponseMessage, IProject } from "../../../types"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

const PortfolioProjectsPage = () => {
  const navigate = useNavigate()
  const [projects, setProjects] = useState<IProject[] | null>(null)

  const fetchProjects = () => {
    api
      .get("/portfolio/projects")
      .then((response: AxiosResponse<IProject[]>) => {
        setProjects(response.data)
      })
  }

  const handleDelete = (id: number, name: string) => {
    if (!window.confirm(`Вы точно хотите удалить этот проект?\n\nНазвание: ${name}`)) return

    api
      .delete<IResponseMessage>(`/portfolio/projects/${id}`)
      .then((res) => {
        toast.success(res.data?.message || "Проект удалён")
        fetchProjects()
      })
      .catch((res) => toast.error(res.data?.message || "Не удалось удалить проект"))
  }

  useEffect(() => {
    fetchProjects()
  }, [])

	return (
    <>
      <header className="ns_categories__header">
      <img src="/img/logo.svg" alt="Image" className="ns_categories__logo"/>
        <button type="button" className="btn btn-primary" onClick={() => navigate("/portfolio/home")}>
          Назад
        </button>
      </header>
      <div className="container py-5">
        <h1 className="text-white mb-5">Управление проектами</h1>
        <button
          onClick={() => navigate(`/portfolio/projects/create`)}
          className="btn btn-light d-block mx-auto my-5"
        >
          <h3>Добавить проект</h3>
        </button>
        <div className="d-flex flex-column-reverse">
          {
            projects?.map((proj) => {
              return (
                <div className="card mb-3 position-relative" key={proj.id}>
                  <span className="position-absolute end-0 top-0 m-3">
                    <button
                      className="d-block btn btn-primary mb-2"
                      onClick={() => navigate(`/portfolio/projects/edit/${proj.id}`)}
                    >
                      Редактировать
                    </button>
                    <button
                      className="d-block btn btn-danger mb-2"
                      onClick={() => handleDelete(proj.id, proj.name)}
                    >
                      Удалить проект
                    </button>
                  </span>
                  <div className="row g-0">
                    <div className="col-4">
                      <img
                        src={proj.main_photo || "/img/placeholder.jpg"}
                        className="img-fluid object-fit-cover h-100 rounded-start h-full ratio-4x3"
                      />
                    </div>
                    <div className="col-7">
                      <div className="card-body">
                        <h5 className="card-title fs-2 mb-1" style={{paddingRight: "4.5rem"}}>{proj.name}</h5>
                        <small className="text-body-secondary">Описание: </small>
                        <p className="card-text fs-4 mb-1">{proj.description}</p>
                        <small className="text-body-secondary">Место: </small>
                        <p className="card-text fs-4 mb-1">{proj.location}</p>
                        <small className="text-body-secondary">Дата: </small>
                        <p className="card-text fs-4">{proj.year}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
	)
}

export default PortfolioProjectsPage
