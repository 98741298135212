import { useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useState } from "react"
import { IResponseMessage, IExhibition } from "../../../types"
import { AxiosResponse } from "axios"
import { api } from "../../../api"
import { toast } from "sonner"

const PortfolioExhibitionsPage = () => {
  const navigate = useNavigate()
  const [exhibitions, setExhibitions] = useState<IExhibition[] | null>(null)

  const fetchExhibitions = () => {
    api
      .get("/portfolio/exhibitions")
      .then((response: AxiosResponse<IExhibition[]>) => {
        setExhibitions(response.data)
      })
  }

  const handleDelete = (id: number, name: string) => {
    if (!window.confirm(`Вы точно хотите удалить эту выставку?\n\nНазвание: ${name}`)) return

    api
      .delete<IResponseMessage>(`/portfolio/exhibitions/${id}`)
      .then((res) => {
        toast.success(res.data?.message || "Выставка удалена")
        fetchExhibitions()
      })
      .catch((err) => {
        toast.error("Не удалось удалить выставку")
        console.error(err)
      })
  }

  useEffect(() => {
    fetchExhibitions()
  }, [])

	return (
    <>
    <header className="ns_categories__header">
    <img src="/img/logo.svg" alt="Image" className="ns_categories__logo"/>
    <button type="button" className="btn btn-primary" onClick={() => navigate("/portfolio/home")}>
      Назад
    </button>
  </header>
    <div className="container py-5">
      <h1 className="text-white mb-5">Управление выставками</h1>
      <button
        onClick={() => navigate(`/portfolio/exhibitions/create`)}
        className="btn btn-light d-block mx-auto my-5"
      >
        <h3>Добавить выставку</h3>
      </button>
      <div className="d-flex flex-column-reverse">
        {
          exhibitions?.map((exhib) => {
            return (
              <div className="card mb-3 position-relative" key={exhib.id}>
                <span className="position-absolute end-0 top-0 m-3">
                  <button
                    className="d-block btn btn-primary mb-2"
                    onClick={() => navigate(`/portfolio/exhibitions/edit/${exhib.id}`)}
                  >
                    Редактировать
                  </button>
                  <button
                    className="d-block btn btn-danger mb-2"
                    onClick={() => handleDelete(exhib.id, exhib.name)}
                  >
                    Удалить выставку
                  </button>
                </span>
                <div className="row g-0">
                  <div className="col-4">
                    <img
                      src={exhib.main_photo || "/img/placeholder.jpg"}
                      className="img-fluid object-fit-contain h-100 rounded-start h-full"
                    />
                  </div>
                  <div className="col-7">
                    <div className="card-body">
                      <h5 className="card-title fs-2 mb-1" style={{paddingRight: "4.5rem"}}>{exhib.name}</h5>
                      <small className="text-body-secondary">Описание: </small>
                      <p className="card-text fs-4 mb-1">{exhib.description}</p>
                      <small className="text-body-secondary">Место: </small>
                      <p className="card-text fs-4 mb-1">{exhib.location}</p>
                      <small className="text-body-secondary">Дата: </small>
                      <p className="card-text fs-4">{exhib.year}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  </>
	)
}

export default PortfolioExhibitionsPage
