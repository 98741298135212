import { useNavigate, useParams } from "react-router-dom"
import "./PortfolioEditProject.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { toast } from "sonner"
import { api } from "../../../api"
import { useEffect, useState } from "react"
import { IProject } from "../../../types"

const PortfolioEditProjectPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [thisProject, setThisProject] = useState<IProject | null>(null)

  useEffect(() => {
    api.get<IProject>(`/portfolio/projects/${id}`)
      .then(response => {
        setThisProject(response.data)
      })
  }, [])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.target as HTMLFormElement
    const formData = new FormData(form)

    const data = {
      description: formData.get("description"),
    }

    try {
      const response = await api.patch(`/portfolio/projects/${id}`, data)
      toast.success(response.data?.message || "Проект обновлён")
      navigate("/portfolio/projects")
    } catch (err: any) {
      console.error(err.message)
      toast.error("Не удалось обновить проект")
    }
  }

	return (
		<>
      <header className="ns_categories__header">
        <img src="/img/logo.svg" alt="Image" className="ns_categories__logo"/>
        <button type="button" className="btn btn-primary" onClick={() => navigate("/portfolio/projects")}>
          Назад
        </button>
      </header>
      <div className="container py-5">
      <form className="card" onSubmit={handleSubmit}>
          <div className="card-header">Редактировать проект "{thisProject?.name}"</div>
          <div className="card-body">

            <div className="mb-3">
              <label htmlFor="description" className="form-label">Описание</label>
              <input
                required
                type="text"
                className="form-control"
                name="description"
                id="description"
                defaultValue={thisProject?.description}
              />
            </div>
            
            <button type="submit" className="btn btn-primary">Редактировать</button>
          </div>
        </form>
      </div>
		</>
	)
}

export default PortfolioEditProjectPage
