import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { Toaster } from 'sonner'

ReactDOM.createRoot(document.getElementById('root')!).render(
    <>
      <App />
      <Toaster richColors position='top-right' style={{marginRight: "115px"}} offset={"25px"}/>
    </>
)
