import { useNavigate, useParams } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import { api } from "../../../api"
import { toast } from "sonner"
import { useEffect, useState } from "react"
import { AxiosResponse } from "axios"
import { IStoreCategory } from "../../../types"

const NetStoreUpdateCategoryPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [category, setCategory] = useState<IStoreCategory | null>(null)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    
    const data = {
      id,
      title: formData.get("categoryTitle"),
      significant_flag: JSON.parse(formData.get("isSignificant") as string)
    }

    try {
      await api.patch(`internet_store/categories/${id}`, data)
      toast.success("Категория обновлена")
      navigate("/store/categories")
      
    } catch(err) {
      console.error(err)
      toast.error("Не удалось обновить категорию")
    }
  }

  useEffect(() => {
    api
      .get(`internet_store/categories/${id}`)
      .then((res: AxiosResponse<IStoreCategory>) => {
        setCategory(res.data)
      })
  }, [])

  if (!category) return <h1 className="text-white text-center mt-5">Загрузка...</h1> // <Loader/>

  return (
    <div className="ns_cteate_cat__body">
      <header className="ns_categories__header">
        <img src="/img/logo.svg" alt="Image" className="ns_categories__logo" />
        <button type="button" className="btn btn-primary" onClick={() => navigate("/store/categories")}>
          Назад
        </button>
      </header>

      <main className="container-sm pt-5">
        <div className="card">
          <div className="card-header ns_create_cat__title">Обновить категорию</div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <label htmlFor="categoryTitle" className="form-label">Название</label>
              <input
                className="form-control"
                id="categoryTitle"
                name="categoryTitle"
                defaultValue={category.title}
                required
              />
              <label className="form-label">Отображается на главной странице</label>
              
              <br />

              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="isSignificant" id="inlineRadio1" value={"true"}
                  defaultChecked={category.significant_flag} />
                <label className="form-check-label" htmlFor="inlineRadio1">Да</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="isSignificant" id="inlineRadio2" value={"false"}
                  defaultChecked={!category.significant_flag} />
                <label className="form-check-label" htmlFor="inlineRadio2">Нет</label>
              </div>
              
              <br />
              
              <button type="submit" className="btn btn-primary ns_create_cat__button">Обновить</button>
            </form>
          </div>
        </div>
        <div className="ns_create_cat-message" id="hider-message">
        </div>
      </main>
    </div>
  )
}

export default NetStoreUpdateCategoryPage
