import { toast } from "sonner"
import "./NetStoreUpdateProduct.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { api } from "../../../api"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { AxiosResponse } from "axios"
import { IStoreCategory, IStoreProduct } from "../../../types"

const NetStoreUpdateProductPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [categories, setCategories] = useState<null | IStoreCategory[]>(null)
  const [product, setProduct] = useState<null | IStoreProduct>(null)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)

    const data = {
      title: formData.get("title"),
      category_id: formData.get("category_id"),
      price: formData.get("price"),
      description: formData.get("description"),
      size: formData.get("size"),
      vendor_code: formData.get("vendor_code"),
      is_available: formData.get("is_available"),
      weight: formData.get("weight")?.toString().trim() || null,
      term_production: formData.get("term_production")?.toString().trim() || null,
      material: formData.get("material")?.toString().trim() || null,
      colors: formData.get("colors")?.toString().trim().split(", ")
    }
    
    if (!data.category_id) {
      toast.error("Категория не выбрана!")
      return
    }

    try {
      await api.put(`internet_store/products/${id}`, data)
      toast.success("Продукт успешно обновлён")
      navigate("/store/products")
    } catch(err) {
      toast.error("Что-то пошло не так при обновлении продукта")
      console.error(err.message, err)
    }
  }

  const fetchCategories = () => {
    api
      .get("internet_store/categories")
      .then((response: AxiosResponse<IStoreCategory[]>) => {
        setCategories(response.data)
      })
      .catch((err) => {
        toast.error("Не удалось запросить категории", {
          onAutoClose: () => navigate(-1),
          duration: 2000
        })
        
        console.error(err)
      })
  }

  const fetchProduct = () => {
    api
      .get(`internet_store/products/${id}`)
      .then((res: AxiosResponse<IStoreProduct>) => {
        setProduct(res.data)
      })
      .catch((err) => {
        toast.error("Не удалось запросить продукт", {
          onAutoClose: () => navigate(-1),
          duration: 2000
        })
        console.error(err)
      })
  }

  useEffect(() => {
    fetchCategories()
    fetchProduct()
  }, [])

  if (!categories || !product) return <h1 className="text-white text-center mt-5">Загрузка...</h1>

  return (
    <div className="ns_create_product__body">
      <header className="ns_create_product__header">
        <img src="/img/logo.svg" alt="Image" className="ns_create_product__logo" />
        <button type="button" className="btn btn-primary"
          onClick={() => navigate(-1)}>Назад</button>
      </header>

      <main className="ns_create_product__main">
        <div className="card">
          <div className="card-header ns_create_product__title">Обновить продукт</div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <label htmlFor="title" className="form-label">Название</label>
              <input
                className="form-control"
                id="title"
                name="title"
                defaultValue={product.title}
                required 
              />

              <label htmlFor="price" className="form-label">Цена</label>
              <input
                type="number"
                className="form-control"
                id="price"
                name="price"
                defaultValue={product.price}
                required
              />

              <label htmlFor="description" className="form-label">Описание</label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                defaultValue={product.description}
                required
              />

              <label htmlFor="size" className="form-label">Размер</label>
              <input
                className="form-control"
                id="size"
                name="size"
                defaultValue={product.size}
                required
              />
              
              <label className="form-label">Наличие</label><br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio1"
                  name="is_available"
                  defaultChecked={product.is_available}
                  value="true"
                  required
                />
                <label className="form-check-label" htmlFor="inlineRadio1">Да</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio2"
                  name="is_available"
                  defaultChecked={!product.is_available}
                  value="false"
                />
                <label className="form-check-label" htmlFor="inlineRadio2">Нет</label>
              </div>

              <br />
              
              <label htmlFor="code" className="form-label">Артикул</label>
              <input
                className="form-control"
                id="code"
                name="vendor_code"
                defaultValue={product.vendor_code}
                required
              />
              
              <label htmlFor="weight" className="form-label">Вес</label>
              <input
                type="number"
                className="form-control"
                step="0.1"
                id="weight"
                name="weight"
                defaultValue={product?.weight || ""}
              />

              <label htmlFor="term_production" className="form-label">Срок производства</label>
              <input
                type="number"
                required
                className="form-control"
                id="term_production"
                name="term_production"
                defaultValue={product?.term_production || ""}
              />

              <label htmlFor="material" className="form-label">Материал</label>
              <input
                className="form-control"
                id="material"
                name="material"
                defaultValue={product?.material || ""}
              />
              <label htmlFor="category" className="form-label">Категория</label>
              
              <select
                className="form-select"
                aria-label="Статус заказа"
                name="category_id"
                id="category"
                defaultValue={product.category_id}
              >
                <option disabled hidden>Выбирите статус...</option>
                {
                  categories.map(category => (
                    <option key={`category_${category.id}`} value={ category.id}>{ category.title }</option>
                  ))
                }
              </select>

              <label htmlFor="colors" className="form-label">Цвета</label>
              {/* TODO: turn into multi select */}
              <input
                type="text"
                className="form-control"
                id="colors"
                name="colors"
                defaultValue={product.colors?.join(", ")}
                placeholder="Через запятую и пробел. Например: красный, синий"
                required
              />
              <button type="submit" className="btn btn-primary ns_create_product__button">Обновить</button>
            </form>
          </div>
        </div>
      </main>
    </div>
  )
}

export default NetStoreUpdateProductPage
